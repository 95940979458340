import TYPES from '@/types';

import Vue from 'vue';

import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetEmergencyFundAvailabilityQuery
  from '@/modules/flagship/catalogs/emergency-fund-availability/application/queries/get-emergency-fund-availability-query';
import UpdateInvestorProfileOnEmergencyFundCommand
  from '@/modules/flagship/investor-profile/emergency-fund/application/commands/update-investor-profile-on-emergency-fund-command';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';

// Domain
import {
  InvestorProfileEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/investor-profile-entity';
import {
  EmergencyFundAvailabilityEntity,
} from '@/modules/flagship/catalogs/emergency-fund-availability/domain/entities/emergency-fund-availability-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipProfilingStepEmergencyFundViewModel {
  @Inject(TYPES.GET_EMERGENCY_FUND_AVAILABILITY_QUERY)
  private readonly get_emergency_fund_query!: GetEmergencyFundAvailabilityQuery;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_ON_EMERGENCY_FUND_COMMAND)
  // eslint-disable-next-line max-len
  private readonly update_emergency_fund_command!: UpdateInvestorProfileOnEmergencyFundCommand;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship_profiling_step_emergency_fund';

  emergency_fund: EmergencyFundAvailabilityEntity[] = [];

  has_emergency_fund: null | boolean = null;

  emergency_fund_amount = 0.00;

  current_savings = '';

  has_emergency_fund_options: Array<any> = [
    {
      label: '',
      value: true,
    },
    {
      label: '',
      value: false,
    },
  ];

  private investor_profile_entity: InvestorProfileEntity = {
    id: '',
    customer_id: sessionStorage.getItem('user_id')!,
    custom_goals: {},
    is_showed: false,
    person_id: '',
    employment_situation_id: '',
    recommended_investment_product_id: '',
    emergency_fund_availability_id: '',
    has_emergency_fund: true,
    net_monthly_expenses: 0,
    elder_dependent_count: 0,
    is_completed: true,
    child_dependent_count: 0,
    pet_dependent_count: 0,
    net_monthly_income: 0,
    active_goals_count: 0,
    goals_completed: false,
    emergency_fund_locations: {},
    retirement_fund_locations: {},
    exit_pool: {},
    finance_related_job: false,
    income_behavior_in_following_year_id: '',
    emergency_fund_amount: 0,
    has_retirement_fund: null,
  }

  get is_disabled() {
    return this.has_emergency_fund === null || (this.has_emergency_fund === true
     && parseFloat(this.current_savings.replace(/[^0-9.]/g, '')) <= 0);
  }

  get emergency_fund_amount_currency_format() {
    return Math.trunc(this.emergency_fund_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  public constructor(view: Vue) {
    this.view = view;
    this.has_emergency_fund_options[0].label = this.translate('yes');
    this.has_emergency_fund_options[1].label = this.translate('no');
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  updateInformation = async () => {
    try {
      this.view.$emit('loadingStep', true);
      this.investor_profile_entity.has_emergency_fund = Boolean(this.has_emergency_fund);
      this.investor_profile_entity.emergency_fund_amount = parseFloat(this.current_savings.replace(/[^0-9.]/g, ''));
      await this.update_emergency_fund_command.execute(this.investor_profile_entity);

      this.view.$emit('nextStep');

      return true;
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.update_profile'),
      );
      return false;
    } finally {
      this.view.$emit('loadingStep', false);
    }
  }

  calculateRecommendedEmergencyFund = (net_monthly_income: number) => {
    this.emergency_fund_amount = net_monthly_income * 3;
  }

  loadEmergencyFundAvailability = async () => {
    try {
      this.emergency_fund = await this.get_emergency_fund_query.execute();
      const daily_emergency_fund_availability = this.emergency_fund.find((emergency_fun) => emergency_fun.name === '24_hours');
      if (daily_emergency_fund_availability) {
        // eslint-disable-next-line max-len
        this.investor_profile_entity.emergency_fund_availability_id = daily_emergency_fund_availability.id;
      }
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_availability'),
      );
    }
  }

  loadInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute(false);
      this.investor_profile_entity.id = investor_profile.id;
      this.calculateRecommendedEmergencyFund(investor_profile.net_monthly_income);
      this.has_emergency_fund = investor_profile.has_emergency_fund;
      if (investor_profile.emergency_fund_amount) {
        this.current_savings = investor_profile.emergency_fund_amount.toString();
      }
    } catch (error) {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.load_investor_profile'),
      );
    }
  }

  initialize = async () => {
    this.view.$emit('loadingStep', true);
    await this.loadEmergencyFundAvailability();
    await this.loadInvestorProfile();
    this.view.$emit('loadingStep', false);
  }
}
