





































































































import { Component, Vue } from 'vue-property-decorator';

import FlagshipProfilingStepEmergencyFundViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-step-emergency-fund-view-model';

@Component({ name: 'FlagshipProfilingStepEmergencyFund' })
export default class FlagshipProfilingStepEmergencyFund extends Vue {
  flagship_emergency_view_model = Vue.observable(
    new FlagshipProfilingStepEmergencyFundViewModel(this),
  );

  created() {
    this.flagship_emergency_view_model.initialize();
  }
}
